// @flow

import React, { PureComponent } from 'react';
import styles from './footer.module.scss';
import { FormattedMessage } from 'react-intl';

export class FooterComponent extends PureComponent<any> {
  render() {
    return (
      <div className={styles.container}>
        <FormattedMessage id="FOOTER.CONTACT" />
      </div>
    );
  }
}
