export const LUNCH_TYPE = {
  BENEFIT: 1,
  DEDUCTIBLE: 2,
};

export const lunchValuesRange = {
  AMOUNT_MIN: 8.6,
  AMOUNT_MAX: 13.7,
  MULTIPLIER_MIN: 1,
  MULTIPLIER_MAX: 90,
};

export const calculateDeductibleAmount = (role, amount = 0, multiplier = 0) =>
  (role === LUNCH_TYPE.DEDUCTIBLE ? 0.75 * Math.max(+amount, lunchValuesRange.AMOUNT_MIN) * +multiplier : 0).toFixed(2);

export const getScopedNumber = (value: String, min: number, max: number) => {
  const number = Number(value);

  if (Number.isNaN(number) || number <= min) {
    return min;
  }

  if (number > max) {
    return max;
  }

  return number;
};
